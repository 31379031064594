<template>
    <div>
        <v-dialog v-model="dialog" persistent scrollable width="600">

            <v-card :disabled="isLoading">

                <v-card-title>
                    Envoyer un consentement
                    <v-spacer/>
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-card-title>

                <v-divider/>

                <v-card-text class="pa-8">

                    <v-alert border="bottom" class="fs-14" dense prominent text type="info">
                        <div class="d-block d-flex">
                            <div class="font-weight-medium">Nom : &nbsp;</div>
                            Il remplace le nom du propriétaire.
                        </div>
                        <div class="d-block d-flex">
                            <div class="font-weight-medium">Url : &nbsp;</div>
                            il remplace le lien d'activation.
                        </div>
                    </v-alert>

                    <v-radio-group v-model="lang"
                                   :error-messages="errors.lang"
                                   label="Sélectionnez la langue des SMS (*)">

                        <v-radio label="Français" value="fr"></v-radio>

                        <v-radio label="Arabe" value="ar"></v-radio>

                    </v-radio-group>

                    <v-textarea ref="textarea"
                                v-model.trim="sms"
                                :class="lang === 'ar' ? 'ar':''"
                                :dir="lang === 'ar' ? 'rtl' : 'ltr'"
                                :disabled="!lang"
                                :error-messages="errors.sms"
                                :label="lang === 'ar' ? 'الرسالة القصيرة (*)':'SMS (*)'"
                                :placeholder="lang === 'ar' ? 'اكتب الرسالة القصيرة هنا...':'Écrivez le SMS ici...'"
                                clearable
                                outlined
                                prepend-inner-icon="mdi-cellphone-message"
                                rows="5"
                    ></v-textarea>

                </v-card-text>

                <v-divider/>

                <v-card-actions class="grey lighten-4">
                    <v-spacer/>
                    <v-btn :loading="isLoading"
                           color="secondary"
                           depressed
                           @click="save">
                        <v-icon left>mdi-email-send-outline</v-icon>
                        Envoyer
                    </v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>

        <ConfirmDialog ref="confirmDialog"/>

    </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            errors: {},

            /**
             * form
             */
            lang: '',
            sms: '',
            salepoint_id: '',

            /***
             *
             */

            smsAr: "سلام {{Nom}} لتلقي الرسائل من كوكا كولا كجزء من برنامج الوفاء، يرجى تفعيل استقبال الرسائل القصيرة من خلال هذا الرابط {{Url}}",
            smsFr: "Salam {{Nom}} activez la réception des SMS Coca-Cola pour le programme de fidélité via : {{Url}}",
        }
    },
    watch: {
        /**
         *
         * @param val
         */
        lang(val) {
            if (val === 'ar') {
                this.sms = this.smsAr
            } else {
                this.sms = this.smsFr
            }
        }
    },
    methods: {
        open(pos) {
            this.salepoint_id = pos.id
            this.lang = 'fr'
            this.sms = this.smsFr
            this.errors = {}
            this.dialog = true
        },
        async save() {
            this.isLoading = true
            HTTP({
                url: '/database/send-consent',
                method: 'POST',
                data: {
                    salepoint_id: this.salepoint_id,
                    lang: this.lang,
                    sms: this.sms,
                },
            }).then(() => {
                this.dialog = false
                this.$successMessage = "Le SMS est en cours d'envoi au propriétaire concerné."
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>