<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          {{ title }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-text-field v-model="form.code"
                        :error-messages="errors.code"
                        dense
                        label="Code *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.name"
                        :error-messages="errors.name"
                        dense
                        label="Nom du propriétaire *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.store_name"
                        :error-messages="errors.store_name"
                        dense
                        label="Nom du magasin *"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.phone"
                        :error-messages="errors.phone"
                        dense
                        label="Numéro du téléphone *"
                        outlined>
          </v-text-field>

          <v-select v-model="form.salepoint_type_id"
                    :error-messages="errors.salepoint_type_id"
                    :items="types"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Type du propriétaire *"
                    outlined>
          </v-select>

          <v-autocomplete v-model="form.city_id"
                          :error-messages="errors.city_id"
                          :items="cities"
                          :loading="isLoading"
                          dense
                          item-text="name"
                          item-value="id"
                          label="Wilaya"
                          outlined>
          </v-autocomplete>


          <v-text-field v-model="form.cluster"
                        :error-messages="errors.cluster"
                        dense
                        label="Cluster"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.senior_regional_manager"
                        :error-messages="errors.senior_regional_manager"
                        dense
                        label="Senior regional manager"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.regional"
                        :error-messages="errors.regional"
                        dense
                        label="Régional"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.area_manager"
                        :error-messages="errors.area_manager"
                        dense
                        label="Chef de zone"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.supervisor"
                        :error-messages="errors.supervisor"
                        dense
                        label="Superviseur"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.pre_seller"
                        :error-messages="errors.pre_seller"
                        dense
                        label="Prévendeur"
                        outlined>
          </v-text-field>

          <v-text-field v-model="form.route"
                        :error-messages="errors.route"
                        dense
                        label="Route"
                        outlined>
          </v-text-field>

          <v-file-input ref="image"
                        :error-messages="errors.image"
                        accept="image/*"
                        chips
                        counter
                        dense
                        label="Image"
                        outlined
                        show-size
                        small-chips
                        @change="uploadImage"
                        @click:clear="resetImage">
          </v-file-input>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['types'],
    data() {
        return {
            isLoading: false,
            cities: [],

            title: '',
            dialog: false,

            saveLoading: false,
            errors: {},
            form: {}
        }
    },
    methods: {
        uploadImage(file) {
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.form.image = reader.result
                }
                reader.readAsDataURL(file)
            }
        },
        open(item) {
            this.resetImage()
            if (!this.cities.length) {
                this.getCities()
            }
            this.errors = {}
            this.dialog = true
            this.form = {
                id: item ? item.id : '',
                code: item ? item.code : '',
                name: item ? item.name : '',
                store_name: item ? item.store_name : '',
                phone: item ? item.phone : '',

                image: '',
                city_id: item ? item.city_id : '',
                salepoint_type_id: item ? item.salepoint_type_id : '',
                database_id: this.$route.params.id,

                cluster: item ? item.cluster : '',
                senior_regional_manager: item ? item.senior_regional_manager : '',
                regional: item ? item.regional : '',
                area_manager: item ? item.area_manager : '',
                supervisor: item ? item.supervisor : '',
                pre_seller: item ? item.pre_seller : '',
                route: item ? item.route : '',
            }
            this.title = item ? 'Modifier un propriétaire' : 'Ajouter un propriétaire'
        },
        save() {
            this.saveLoading = true
            this.errors = {}

            HTTP({
                url: this.form.id ? '/database/pos/' + this.form.id + '/update' : '/database/pos/store',
                method: this.form.id ? 'put' : 'post',
                data: this.form,
            }).then(() => {
                if (this.form.id) {
                    this.dialog = false
                }
                this.$emit('refresh')
                this.$successMessage = 'Cet élément a ete enregistré avec succes'
                this.saveLoading = false
                this.resetImage()

                this.form = {
                    id: '',
                    code: '',
                    name: '',
                    store_name: '',
                    phone: '',

                    image: '',
                    city_id: '',

                    cluster: '',
                    senior_regional_manager: '',
                    regional: '',
                    area_manager: '',
                    supervisor: '',
                    pre_seller: '',
                    route: '',

                    salepoint_type_id: '',
                    database_id: this.$route.params.id,
                }

            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        resetImage() {
            if (this.$refs.image) {
                this.$refs.image.reset()
            }
            this.form.image = ''
        },
        getCities() {
            this.isLoading = true
            HTTP.get('database/cities').then(res => {
                this.cities = res.data.data.cities
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
}
</script>

<style scoped>

</style>