<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-select v-model="filter.statuses"
                    :items="statuses"
                    dense
                    item-text="name"
                    item-value="value"
                    label="Consentement"
                    multiple
                    outlined>
          </v-select>

          <v-autocomplete v-model="filter.cityIds"
                          :items="filterElements.cities"
                          dense
                          item-text="name"
                          item-value="id"
                          label="Wilaya"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.salepointTypeIds"
                          :items="filterElements.salepoint_types"
                          dense
                          item-text="name"
                          item-value="id"
                          label="Type"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.clusters"
                          :items="filterElements.clusters"
                          dense
                          label="Cluster"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.supervisors"
                          :items="filterElements.supervisors"
                          dense
                          label="Superviseurs"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.regionals"
                          :items="filterElements.regionals"
                          dense
                          label="Régionaux"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.pre_sellers"
                          :items="filterElements.pre_sellers"
                          dense
                          hide-details
                          label="Pré-vendeurs"
                          multiple
                          outlined>
          </v-autocomplete>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn color="secondary"
                 depressed
                 @click="search">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            filter: {},
            filterElements: {},
            statuses: [
                {name: 'En attente', value: 'pending'},
                {name: 'Envoyé', value: 'sent'},
                {name: 'Reçu', value: 'received'},
                {name: 'Approuvé', value: 'approved'},
                {name: 'Échoué', value: 'error'},
                {name: 'SMS non envoyé', value: 'no_sms'},
            ]
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.distinctData()
        },
        search() {
            this.dialog = false
            this.$emit('search', this.filter)
        },
        distinctData() {
            this.isLoading = true
            HTTP.get('database/distinct-data').then(res => {
                this.filterElements = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },

    }
}
</script>

<style scoped>

</style>