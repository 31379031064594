<template>
  <div>
    <v-container fluid>
      <v-card class="shadow rounded-lg">

        <Overlay :overlay="overlay"/>

        <v-card-title v-if="isLoading">
          <v-skeleton-loader type="text,text" width="250"></v-skeleton-loader>
          <v-spacer/>
          <v-skeleton-loader type="text,text" width="150"></v-skeleton-loader>
        </v-card-title>

        <v-card-title v-else>

          {{ databaseName }}

          <v-chip class="bg-primary-subtle font-weight-medium ml-2"
                  small
                  text-color="secondary">
            Total propriétaires : {{ total }}
          </v-chip>

          <v-spacer/>

          <v-btn class="ml-2"
                 color="red"
                 exact
                 left
                 text
                 to="/database">
            <v-icon color="red" left>mdi-chevron-left</v-icon>
            Retour
          </v-btn>

        </v-card-title>

        <v-divider/>

        <v-card-title>

          <div class="d-flex align-center">
            <v-text-field v-model="keyword"
                          :style="{width : '250px'}"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          hide-details
                          outlined placeholder="Recherche..."
                          single-line/>

            <v-text-field v-model="code"
                          :style="{width : '180px'}"
                          append-icon="mdi-magnify"
                          class="ml-2"
                          clearable
                          dense
                          hide-details
                          outlined placeholder="Code..."
                          single-line/>
          </div>

          <v-spacer/>

          <v-btn v-if="Object.keys(filter).length"
                 class="grey lighten-5 mr-2"
                 color="red"
                 text @click="[filter = {},getDatabase()]">
            <v-icon left>mdi-filter-off</v-icon>
            Filtre
          </v-btn>

          <v-btn v-if="$route.name !== 'database.global'"
                 class="mr-2"
                 color="secondary"
                 depressed @click="$refs.importDialog.open()">
            <v-icon left>mdi-import</v-icon>
            Importer
          </v-btn>

          <v-btn v-if="$route.name !== 'database.global'"
                 color="secondary"
                 text
                 @click="$refs.salepointForm.open()">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>

          <v-btn color="secondary" text
                 @click="$func.export($route.name === 'database.global' ? 'database/export-global-excel' :'database/export-excel',{database_id : $route.params.id})">
            <v-icon left>mdi-download</v-icon>
            Expoter
          </v-btn>

          <v-btn color="secondary" text @click="$refs.filterDatabaseDialog.open()">
            <v-icon left>mdi-filter</v-icon>
            Filtre
          </v-btn>

          <v-btn color="secondary" icon @click="getDatabase">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

        </v-card-title>

        <v-divider/>

        <v-card-text>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>
            <div v-if="salepoints.length">
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>

                    <th>Code</th>

                    <th class="text-no-wrap">
                      Nom du
                      <br>
                      propriétaire
                    </th>

                    <th class="text-no-wrap">
                      Nom du
                      <br>
                      magasin
                    </th>

                    <th class="text-no-wrap">
                      Numéro du
                      <br>
                      téléphone
                    </th>

                    <th class="text-no-wrap">
                      Type du
                      <br>
                      magasin
                    </th>

                    <th>Wilaya</th>

                    <th>Consentement</th>

                    <th>
                      <span class="text-no-wrap">Date d'envoi du </span>
                      <br>consentement
                    </th>

                    <th class="text-no-wrap">Approuvé le</th>

                    <th>Actions</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in salepoints" :key="index">

                    <td>
                      <span class="font-weight-medium red--text">{{ item.code }}</span>
                    </td>

                    <td>
                      <div :class="$func.containsArabic(item.name) &&  'ar'"
                           class="d-flex align-center">

                        <v-avatar v-if="item.image" size="38">
                          <v-img :src="$baseUrl + item.image"></v-img>
                        </v-avatar>

                        <v-avatar v-else
                                  :class="$func.randomColor()"
                                  class="font-weight-medium"
                                  size="38">
                          {{ $func.avatarName(item.name) }}
                        </v-avatar>

                        <span :class="$func.containsArabic(item.name) ? 'mr-2' : 'ml-2'">
                          {{ item.name }}
                        </span>
                      </div>
                    </td>

                    <td :class="$func.containsArabic(item.name) &&  'ar'">
                      {{ item.store_name }}
                    </td>

                    <td>
                      {{ item.phone }}
                    </td>

                    <td class="font-weight-medium">
                      {{ item.salepoint_type ? item.salepoint_type.name : '-' }}
                    </td>

                    <td>
                      {{ item.city ? item.city.name : '-' }}
                    </td>

                    <td>
                      <div class="d-block text-no-wrap grey--text text--darken-1">
                        <v-icon :color="switchStatus(item.consent_status)['color']" small>
                          {{ switchStatus(item.consent_status)['icon'] }}
                        </v-icon>
                        {{ switchStatus(item.consent_status)['value'] }}
                      </div>
                    </td>

                    <td class="text-no-wrap">
                      {{item.consentement && item.consentement.sent_at ? $func.dateFormat(item.consentement.sent_at) : '-'}}
                    </td>

                    <td class="text-no-wrap">
                      {{ item.consent_approved_at ? $func.dateFormat(item.consent_approved_at) : '-'}}
                    </td>

                    <td>
                      <div class="d-flex">

                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn color="red" icon @click="deleteSalepoint(item.id,index)"
                                   v-on="on">
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          <span>Supprimer</span>
                        </v-tooltip>

                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn color="secondary" icon
                                   @click="$refs.salepointForm.open(item)" v-on="on">
                              <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Modifier</span>
                        </v-tooltip>

                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn color="secondary" icon
                                   @click="$refs.salepointDetailDialog.open(item)"
                                   v-on="on">
                              <v-icon>mdi-eye-plus-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Informations détaillées</span>
                        </v-tooltip>


                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on }">
                            <v-btn color="secondary" icon
                                   :disabled="item.consent_status === 'approved'"
                                   @click="$refs.sendConsentDialog.open(item)" v-on="on">
                              <v-icon>mdi-cellphone-message</v-icon>
                            </v-btn>
                          </template>
                          <span>Envoyer un consentement</span>
                        </v-tooltip>

                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"

                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
            <NoResult v-else/>
          </div>

        </v-card-text>
      </v-card>

      <ConfirmDialog ref="confirmDialog"/>
      <FilterDatabaseDialog ref="filterDatabaseDialog"
                            @search="[pagination.current = 1,filter = $event,getDatabase()]"/>
      <ImportDialog ref="importDialog" @refresh="getDatabase"/>
      <SalepointForm ref="salepointForm" :types="types" @refresh="getDatabase"/>
      <SalepointDetailDialog ref="salepointDetailDialog"/>
      <SendConsentDialog ref="sendConsentDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import ImportDialog from "@/views/database/show/components/ImportDialog.vue";
import SalepointForm from "@/views/database/show/components/SalepointForm.vue";
import FilterDatabaseDialog from "@/views/database/show/components/FilterDatabaseDialog.vue";
import SalepointDetailDialog from "@/views/database/show/components/SalepointDetailDialog.vue";
import SendConsentDialog from "@/views/database/show/components/SendConsentDialog.vue";

export default {
  components: {ImportDialog, SendConsentDialog, SalepointForm, FilterDatabaseDialog, SalepointDetailDialog},
  data() {
    return {
      typeLoading: false,
      types: [],

      salepoints: [],
      overlay: false,
      isLoading: false,

      keyword: null,
      code: null,
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      filter: {},
      databaseName: ''
    }
  },

  methods: {
    getDatabase() {
      this.isLoading = true

      let url = this.$route.params.id ?
          '/database/' + this.$route.params.id + '/show?page=' + this.pagination.current
          : '/database/global?page=' + this.pagination.current

      HTTP.get(url, {
        params: {
          keyword: this.keyword,
          code: this.code,
          per_page: this.per_page,
          filter: this.filter,
          on_global: this.$route.name === 'database.global',
        }
      }).then((res) => {
        this.isLoading = false
        this.salepoints = res.data.data.data

        this.databaseName = res.data.database_name
        this.pagination.current = res.data.data.current_page
        this.pagination.total = res.data.data.last_page
        this.total = res.data.data.total

      }).catch(err => {
        this.isLoading = false
        console.log(err)
        if (err.response.status === 404) {
          this.$router.push('/404')
        }
      })
    },
    async deleteSalepoint(id, index) {
      if (await this.$refs.confirmDialog.open()) {
        this.overlay = true
        HTTP.delete('database/pos/' + id + '/delete').then(() => {
          this.salepoints.splice(index, 1)
          this.overlay = false
          this.$successMessage = 'Cet élément a été supprimé avec succès.'
        }).catch(err => {
          this.overlay = false
          console.log(err)
        })
      }
    },
    onPageChange() {
      this.getDatabase();
    },
    getSalepointTypes() {
      this.typeLoading = true
      HTTP.get('salepoint-types').then(res => {
        this.types = res.data.data
        this.typeLoading = false
      }).catch(err => {
        this.typeLoading = false
        console.log(err)
      })
    },
    switchStatus(status) {
      switch (status) {
        case "sent":
          return {
            value: 'Envoyé',
            icon: 'mdi-email-send',
            color: 'info',
          }
        case "approved":
          return {
            value: 'Approuvé',
            icon: 'mdi-check-circle',
            color: 'teal',
          }
        case "error":
          return {
            value: 'Échoué',
            icon: 'mdi-alert',
            color: 'red',
          }
        case "received":
          return {
            value: 'Reçu',
            icon: 'mdi-cellphone-message',
            color: 'success',
          }

        case "pending":
          return {
            value: 'En attente',
            icon: 'mdi-timer',
            color: 'orange',
          }

        case "no_sms":
          return {
            value: 'Sms non envoyé',
            icon: 'mdi-cellphone-message-off',
            color: 'yellow',
          }

      }
    }
  },
  created() {
    this.getSalepointTypes()
    this.getDatabase()
  },
  watch: {
    keyword() {
      this.getDatabase();
    },
    code() {
      this.getDatabase();
    },
    per_page() {
      this.pagination.current = 1
      this.getDatabase();
    },
  },
}
</script>

<style scoped>

</style>