<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card :disabled="saveLoading" :loading="saveLoading">

        <v-card-title>
          Importer une base de données
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-subtitle>
          Cette opération peut créer ou mettre à jour s'il existe.
        </v-card-subtitle>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-alert v-for="(errs,i) in file_errors"
                   :key="i"
                   border="left"
                   class="fs-15"
                   dense
                   dismissible
                   prominent
                   text
                   type="error">
            <p> La ligne {{ errs[0].row }} n'est pas enregistrée car</p>
            <ul>
              <li v-for="(err,index) in errs" :key="index">
                {{ err.message }}
              </li>
            </ul>
          </v-alert>

          <v-file-input ref="file"
                        :error-messages="errors.file"
                        accept=".xlsx, .xls"
                        chips
                        counter
                        dense
                        label="Excel *"
                        outlined
                        show-size
                        small-chips
                        @change="uploadFile"
                        @click:clear="resetFile">
          </v-file-input>

          <v-alert color="red" text>
            <v-switch v-model="form.use_validation"
                      class="pa-m ma-0"
                      hide-details label="Importer avec vérification des champs vides"/>
          </v-alert>

          <v-alert class="fs-14" color="info" text>
            <p class="font-weight-medium">
              Les types du propriétaire doit être écrit comme suit :
            </p>
            <ul>
              <li>AG</li>
              <li>BT</li>
              <li>SUP</li>
              <li>REST</li>
              <li>HOTEL</li>
              <li>CAFE</li>
              <li>FF</li>
              <li>AUTRE</li>
              <li>KMS</li>
              <li>PATISSERIE</li>
              <li>PIZ</li>
            </ul>
          </v-alert>

          <v-expansion-panels class="mt-5" dark flat>
            <v-expansion-panel class="info">
              <v-expansion-panel-header v-slot:default="{ open }" class="font-weight-medium" expand-icon="">

                <div>
                  <v-icon>mdi-information-outline</v-icon>
                  Merci de respecter ce format excel ci-dessous
                </div>

                <div class="text-end">
                  <v-icon v-if="open">
                    mdi-minus-circle
                  </v-icon>

                  <v-icon v-else>
                    mdi-plus-circle
                  </v-icon>
                </div>

              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table light>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Nom de colomn</th>
                      <th>Position</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in colomns" :key="i">
                      <td>{{ item.name }}</td>
                      <td width="40%">
                        <v-text-field v-model="item.position" dense disabled hide-details outlined
                                      placeholder="A,B,C,D..."/>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>


        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-btn color="secondary"
                 text
                 @click="downlaodExample">
            <v-icon left>mdi-download</v-icon>
            Télécharger une exemple
          </v-btn>
          <v-spacer/>
          <v-btn :loading="saveLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-import</v-icon>
            Importer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    name: "ProductForm",
    props: ['brands'],
    data() {
        return {
            dialog: false,
            saveLoading: false,
            errors: {},
            file: '',
            form: {
                use_validation: true
            },
            colomns: [
                {name: 'Code(*)', position: 'A'},
                {name: 'Nom du propriétaire(*)', position: 'B'},
                {name: 'Nom du magasin(*)', position: 'C'},
                {name: 'Type du magasin(*)', position: 'C'},
                {name: 'Numéro du téléphone(*)', position: 'E'},
                {name: 'Wilaya(optionnel)', position: 'F'},
                {name: 'Cluster(optionnel)', position: 'G'},
                {name: 'Senior regional manager(optionnel)', position: 'H'},
                {name: 'Régional(optionnel)', position: 'I'},
                {name: 'Chef de zone(optionnel)', position: 'J'},
                {name: 'Superviseur(optionnel)', position: 'K'},
                {name: 'Prévendeur(optionnel)', position: 'L'},
                {name: 'Route(optionnel)', position: 'M'},
            ],
            file_errors: []
        }
    },
    methods: {
        uploadFile(file) {
            this.file = file
        },
        open() {
            this.resetFile()
            this.form = {
                use_validation: true
            }
            this.errors = {}
            this.file_errors = []
            this.dialog = true
        },
        save() {
            this.saveLoading = true
            this.errors = {}
            this.file_errors = []
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('database_id', this.$route.params.id)
            formData.append('use_validation', this.form.use_validation ? '1' : '0')
            HTTP({
                url: 'database/import',
                method: 'post',
                data: formData,
            }).then(() => {
                this.dialog = false
                this.$emit('refresh')
                this.$successMessage = 'Cette base de données a été importée avec succes'
                this.saveLoading = false
            }).catch(err => {
                this.saveLoading = false
                this.errors = err.response.data.errors || {}
                this.file_errors = err.response.data.file_errors || []
                if (this.file_errors.length) {
                    this.$emit('refresh')
                }
                console.log(err)
            })
        },
        resetFile() {
            if (this.$refs.file) {
                this.$refs.file.reset()
            }
            this.file = ''
        },
        downlaodExample() {
            window.open(this.$baseUrl + '/exampleDatabase.xlsx')
        }
    }
}
</script>

<style scoped>

</style>