<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">

      <v-card>

        <v-card-title>
          Informations détaillées
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-simple-table class="rounded table-border">
            <template v-slot:default>
              <tbody>

              <tr>
                <td class="font-weight-medium">Image</td>
                <td>
                  <v-avatar v-if="salepoint.image" size="38">
                    <v-img :src="$baseUrl + salepoint.image"></v-img>
                  </v-avatar>

                  <div v-else :class="$func.containsArabic(salepoint.name) &&  'ar text-left'">
                    <v-avatar :class="$func.randomColor()"
                              class="font-weight-medium"
                              size="38">
                      {{ $func.avatarName(salepoint.name) }}
                    </v-avatar>
                  </div>
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Code</td>
                <td>
                  {{ salepoint.code }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Nom de propriétaire</td>
                <td :class="$func.containsArabic(salepoint.name) &&  'ar text-left'">
                  {{ salepoint.name }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Non du magasin</td>
                <td :class="$func.containsArabic(salepoint.store_name) &&  'ar text-left'">
                  {{ salepoint.store_name }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Numéro du téléphone</td>
                <td>
                  {{ salepoint.phone }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Type du magasin</td>
                <td>
                  {{ salepoint.salepoint_type ? salepoint.salepoint_type.name : '-' }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Wilaya</td>
                <td>
                  {{ salepoint.city ? salepoint.city.name : '-' }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Cluster</td>
                <td>
                  {{ salepoint.cluster }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Senior regional manager</td>
                <td>
                  {{ salepoint.senior_regional_manager }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Régional</td>
                <td>
                  {{ salepoint.regional }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Chef de zone</td>
                <td>
                  {{ salepoint.area_manager }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Superviseur</td>
                <td>
                  {{ salepoint.supervisor }}
                </td>
              </tr>

              <tr>
                <td class="font-weight-medium">Prévendeur</td>
                <td>
                  {{ salepoint.pre_seller }}
                </td>
              </tr>

              <tr class="grey lighten-4">
                <td class="font-weight-medium">Route</td>
                <td>
                  {{ salepoint.route }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            errors: {},
            salepoint: {},
        }
    },
    methods: {
        open(salepoint) {
            this.salepoint = salepoint
            this.dialog = true
        },
    }
}
</script>

<style scoped>

</style>